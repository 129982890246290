import { useEffect, useRef, useState } from 'react';

import { setModalAuthOpen } from 'src/redux/slices/authSlice';
import { selectAuth, useAppDispatch, useAppSelector } from 'src/redux/store';

const useAuthAction = () => {
	const dispatch = useAppDispatch();
	const { user, modalAuthOpen } = useAppSelector(selectAuth);

	const [running, setRunning] = useState<boolean>(false);
	const actionRef: any = useRef(null);

	const closeModalAuth = () => {
		dispatch(setModalAuthOpen(false));
	};

	const callAuthAction = (action?: (...args: any[]) => void) => {
		actionRef.current = action ?? null;
		setRunning(true);
	};

	const triggerTargetAction = async () => {
		if (actionRef?.current) {
			await actionRef.current();
		}
		actionRef.current = null;
		setRunning(false);
	};

	useEffect(() => {
		if (running) {
			if (!user?.api_token) {
				dispatch(setModalAuthOpen(true));
			} else {
				triggerTargetAction();
			}
		}
	}, [JSON.stringify(user), running]);

	useEffect(() => {
		if (!modalAuthOpen) {
			setRunning(false);
		}
	}, [modalAuthOpen]);

	return { modalAuthOpen, closeModalAuth, callAuthAction };
};

export default useAuthAction;

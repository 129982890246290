/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
import { useEffect } from 'react';
import {
	createWeb3Modal,
	defaultConfig,
	useDisconnect,
	useWeb3ModalAccount,
	useWeb3ModalProvider,
} from '@web3modal/ethers5/react';
import { ethers } from 'ethers';
import Web3 from 'web3';

import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { resetWeb3State, setWeb3Balance } from 'src/redux/slices/web3Slice';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import web3config from 'src/utils/web3config';

export interface IWeb3modalChain {
	chainId: number;
	name: string;
	currency: string;
	explorerUrl: string;
	rpcUrl: string;
	icon?: string;
}

// 1. Get projectId at https://cloud.walletconnect.com
const { projectId } = web3config;

// 2. Set chains
const mainnet: IWeb3modalChain = {
	chainId: 1,
	name: 'Ethereum',
	currency: 'ETH',
	explorerUrl: 'https://etherscan.io',
	rpcUrl: 'https://cloudflare-eth.com',
	icon: 'https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg',
};

const bsc: IWeb3modalChain = {
	chainId: 56,
	name: 'BNB Smart Chain',
	currency: 'BNB',
	explorerUrl: 'https://bscscan.com',
	rpcUrl: 'https://rpc.ankr.com/bsc',
	icon: 'https://icons.llamao.fi/icons/chains/rsz_binance.jpg',
};

const arbitrum: IWeb3modalChain = {
	chainId: 42161,
	name: 'Arbitrum One',
	currency: 'ETH',
	explorerUrl: 'https://arbiscan.io',
	rpcUrl: 'https://arb1.arbitrum.io/rpc',
	icon: 'https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg',
};

const okc: IWeb3modalChain = {
	chainId: 66,
	name: 'OKC',
	currency: 'OKT',
	explorerUrl: 'https://www.oklink.com/okc',
	rpcUrl: 'https://exchainrpc.okex.org',
	icon: 'https://icons.llamao.fi/icons/chains/rsz_okexchain.jpg',
};

export const web3modalChains: IWeb3modalChain[] = [mainnet, bsc, arbitrum, okc];

// 3. Create modal
const metadata = {
	name: 'My Website',
	description: 'My Website description',
	url: 'https://creoplay.app',
	icons: ['https://creoplay.app/static/favicon-96x96.png'],
};

createWeb3Modal({
	ethersConfig: defaultConfig({
		metadata,
		enableInjected: false,
		enableCoinbase: true,
		rpcUrl: 'https://cloudflare-eth.com', // used for the Coinbase SDK
		defaultChainId: 1, // used for the Coinbase SDK
	}),
	chains: web3modalChains,
	defaultChain: web3modalChains[0],
	projectId,
	featuredWalletIds: [
		'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
		// 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
		'4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
		'38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
		'971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
	],
	includeWalletIds: [
		'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
		'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
		'4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
		'38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
		'971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
	],
	themeVariables: {
		'--w3m-z-index': 10000,
	},
});

export const useWeb3 = () => {
	const { creoBalance, tokenBalance } = useAppSelector((state) => state.web3slice);

	const { isConnected, address, chainId } = useWeb3ModalAccount();

	const dispatch = useAppDispatch();

	const { clearUser } = useAuthReducer();

	const { disconnect: disconnectWeb3Modal } = useDisconnect();

	const { creoAbi, creoAddress } = web3config;

	const web3ModalProviderData = useWeb3ModalProvider();
	const provider = web3ModalProviderData?.walletProvider as any;

	const getContract = async (abiString: string, contractAddress: string) => {
		const web3 = new Web3(provider as any);
		const abi = JSON.parse(abiString);
		const contract = new web3.eth.Contract(abi, contractAddress);
		return contract;
	};

	const connect = async () => {
		if (provider) {
			try {
				const web3Provider = new ethers.providers.Web3Provider(provider);
				const signer = web3Provider.getSigner();
				const nAddress = await signer.getAddress();

				if (creoAbi && creoAddress) {
					const web3 = new Web3(provider as any);
					const creoContract = await getContract(creoAbi, creoAddress);
					if (creoContract) {
						const nTokenBalance = await creoContract.methods.balanceOf(nAddress).call();
						const nCreoBalance = web3.utils.fromWei(nTokenBalance);

						dispatch(
							setWeb3Balance({
								creoBalance: parseFloat(nCreoBalance),
								tokenBalance: Number(nTokenBalance),
							}),
						);
					}
				}
			} catch (e) {
				// console.log('connect error', e);
			}
		} else {
			// console.error('No Web3Modal');
			localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
		}
	};

	const disconnect = async () => {
		if (provider) {
			clearUser();
			if (disconnectWeb3Modal && typeof disconnectWeb3Modal === 'function') {
				await disconnectWeb3Modal();
			}

			dispatch(resetWeb3State());
		} else {
			// console.error('No Web3Modal');
		}
	};

	// Auto connect to the cached provider
	useEffect(() => {
		if (provider) {
			connect();
		}
	}, [provider]);

	// EIP-1193 events
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (provider?.on) {
			const handleAccountsChanged = () => {
				clearUser();

				if (typeof window !== 'undefined') {
					// console.log('switched to chain...', _hexChainId);
					window.location.reload();
				} else {
					// console.log('window is undefined');
				}
			};

			// eslint-disable-next-line no-unused-vars
			const handleChainChanged = () =>
				// _hexChainId: string
				{
					if (typeof window !== 'undefined') {
						// console.log('switched to chain...', _hexChainId);
						window.location.reload();
					} else {
						// console.log('window is undefined');
					}
				};

			const handleDisconnect = (/*error: { code: number; message: string }*/) => {
				localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
				// eslint-disable-next-line no-console
				// console.log('disconnect', error);
				disconnect();
			};

			provider.on('accountsChanged', handleAccountsChanged);
			provider.on('chainChanged', handleChainChanged);
			provider.on('disconnect', handleDisconnect);

			// Subscription Cleanup
			return () => {
				if (provider.removeListener) {
					provider.removeListener('accountsChanged', handleAccountsChanged);
					provider.removeListener('chainChanged', handleChainChanged);
					provider.removeListener('disconnect', handleDisconnect);
				}
			};
		}
	}, [provider, disconnect]);

	return {
		isConnected,
		provider,
		address,
		chainId,
		creoBalance,
		tokenBalance,
		connect,
		disconnect,
		getContract,
	};
};

import dynamic from 'next/dynamic';

import useAuthAction from 'src/hooks/useAuthAction';

import classes from './index.module.less';

const Header = dynamic(() => import('src/components/Header'));
const HeaderConnectWallet = dynamic(() => import('src/components/HeaderConnectWallet'), {
	ssr: false,
});
const ModalAuth = dynamic(() => import('src/components/ModalAuth'));
const Footer = dynamic(() => import('src/components/Footer'));

const MainLayout = (props: any) => {
	const { children, header } = props;

	const { modalAuthOpen, closeModalAuth } = useAuthAction();

	return (
		<div className={classes['main-layout']}>
			<ModalAuth open={modalAuthOpen} onClose={closeModalAuth} />
			<HeaderConnectWallet />
			<Header {...header} />
			<div>{children}</div>
			<Footer />
		</div>
	);
};

export default MainLayout;
